'use client';
import TopNavBar from '@/components/navigation/TopNavBar';

const WorkspaceLayout = ({ params, children }) => {
  return (
    <div className="h-full">
      <TopNavBar params={params} />
      {children}
    </div>
  );
};
export default WorkspaceLayout;
